import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import { toast } from "react-toastify";
import { navigate } from "gatsby";
import { DetailField } from "./detailField";
import jwtDecode from "jwt-decode";
import { isBrowser } from "../../utils/general";
import { getUserProfile } from "./graphql/queries";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { deleteUserMutation } from "./graphql/mutations";
import { ListGroup, ListGroupItem } from "reactstrap";
import { LanguageContext } from "../../lang";

const MyAccount = () => {
  const {
    translations: { myAccount },
  } = useContext(LanguageContext);
  const {
    myAccountText,
    accDetailsText,
    accDetailsSubText,
    contactDetails,
    nameText,
    emailtext,
    phoneText,
    passwordText,
    deleteText,
    deleteSubText,
    deleteBtnText,
    changeBtnText,
    myBookingText,
  } = myAccount;
  let driverName = "---------";
  let emailAddress = "--------";
  let phoneNumber = "----------";

  const [getUserProfileTrigger, { data, loading, error }] = useLazyQuery(
    getUserProfile
  );
  const [
    deleteUserTrigger,
    {
      data: deleteUserData,
      loading: deleteUserLoading,
      error: deleteUserError,
    },
  ] = useMutation(deleteUserMutation);
  useEffect(() => {
    let token = isBrowser() ? JSON.parse(localStorage.getItem("token")) : {};
    let sessionToken = isBrowser()
      ? JSON.parse(sessionStorage.getItem("token"))
      : {};
    console.log("token from localStorage", token);
    if (token || sessionToken) {
      const { _id } = jwtDecode(token ? token : sessionToken);
      getUserProfileTrigger({
        variables: {
          id: _id,
        },
      });
    }
  }, []);
  const deleteUserHandler = () => {
    let token = isBrowser() ? JSON.parse(localStorage.getItem("token")) : {};
    let sessionToken = isBrowser()
      ? JSON.parse(sessionStorage.getItem("token"))
      : {};
    if (token || sessionToken) {
      const { _id } = jwtDecode(token ? token : sessionToken);
      deleteUserTrigger({
        variables: {
          id: _id,
        },
      });
    }
  };
  if (deleteUserData) {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate("/");
  }
  if (deleteUserError) {
    toast.error(deleteUserError.message, {
      autoClose: 1200,
    });
  }
  if (data) {
    driverName = data.customer.username;
    emailAddress = data.customer.email;
    phoneNumber = data.customer.phoneNumber;
  }
  if (loading) {
    console.log("loading from apollo query is", loading);
  }
  if (error) {
    toast.error("Some thing went wrong", {
      autoClose: 1200,
    });
  }
  return (
    <div className="container-fluid d-flex .mx-2 hm-login-bg justify-content-center align-items-center">
      <div id="dashboard">
        <div className="blueBackgroundHeader"></div>
        <div className="container col-12">
          <div className="row">
            <div
              className="col-12 col-md-3 accAndBookingCard"
              style={{ cursor: "pointer" }}
            >
              <div className="row">
                <div className="col-sm">
                  <ul className="list-group flex-sm-column flex-row justify-content-center my-1">
                    <li
                      className="list-group-item"
                      style={{
                        background: "linear-gradient(90deg,#feb532,#ed5523)",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "rgba(255, 255, 255, 1)",
                      }}
                      active={true}
                      tag="a"
                      onClick={() => navigate("/my-account")}
                    >
                      {myAccountText}
                    </li>
                    <li
                      className="list-group-item"
                      style={{
                        backgroundColor: "white",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "rgba(8,10,97,1)",
                      }}
                      active={false}
                      tag="a"
                      onClick={() => navigate("/my-bookings")}
                    >
                      {myBookingText}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-9 absolutePostionBox mx-0 px-4 py-3">
              <p className="myAccountHeading">{myAccountText}</p>
              <p className="accountDetailHeading">{accDetailsText}</p>
              <p className="accountDetailDescription">{accDetailsSubText}</p>
              <p className="contactDetailHeading">{contactDetails}</p>
              <DetailField
                FieldHeading={nameText}
                FieldValue={driverName}
                FieldType="username"
                Type="User Name "
                ShowButton={true}
                ShowBorderLine={true}
              />
              <DetailField
                FieldHeading={emailtext}
                FieldValue={emailAddress}
                FieldType="email"
                Type="Email "
                ShowButton={true}
                ShowBorderLine={true}
              />
              <DetailField
                FieldHeading={phoneText}
                FieldValue={phoneNumber ? phoneNumber : "--------"}
                Type="Phone Number"
                FieldType="phoneNumber"
                ShowButton={true}
                ShowBorderLine={true}
              />
              <DetailField
                FieldHeading={passwordText}
                Type="Password"
                FieldValue="********************"
                FieldType="password"
                ShowButton={true}
                ShowBorderLine={true}
              />
              <DetailField
                FieldHeading={deleteText}
                FieldValue={deleteSubText}
                ShowButton={false}
              />
              <div
                onClick={() => deleteUserHandler()}
                className="deleteButton py-1"
              >
                <p className="buttonText">{deleteBtnText}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { MyAccount };

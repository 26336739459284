import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { MyAccount as MyAccountPage } from '../components/MyAccount'
import { isLoggedIn } from '../utils/protectedRoute'
const MyAccount = () =>
	!isLoggedIn() ? (
		<h1>you are not loggedIn </h1>
	) : (
		<Layout>
			<SEO title='Login' />
			<MyAccountPage />
		</Layout>
	)

export default MyAccount

import React, { useState, useContext } from 'react'
import './style.css'
import { toast } from 'react-toastify'
import { Link, navigate } from 'gatsby'
import { LanguageContext } from '../../../lang'

const DetailField = ({
  FieldHeading,
  FieldValue,
  FieldType,
  Type,
  ShowButton,
  ShowBorderLine
}) => {
  const {
    translations: { myAccount }
  } = useContext(LanguageContext)
  const {
    myAccountText,
    accDetailsText,
    accDetailsSubText,
    contactDetails,
    nameText,
    emailtext,
    phoneText,
    passwordText,
    deleteText,
    deleteSubText,
    deleteBtnText,
    changeBtnText,
    myBookingText
  } = myAccount
  return (
    <div className={`detailField ${ShowBorderLine ? 'showBorder' : null}`}>
      <div className='detailsDiv'>
        <p className='nameHeading'>{FieldHeading}</p>
        <p className='nameText'>{FieldValue}</p>
      </div>
      {ShowButton ? (
        <div
          onClick={() =>
            FieldType !== 'password'
              ? navigate('/update-profile', {
                  state: {
                    type: Type,
                    fieldName: FieldType,
                    fieldValue: FieldValue
                  }
                })
              : navigate('/update-password')
          }
          className='buttonDiv py-1'
        >
          <p className='buttonText'>{changeBtnText}</p>
        </div>
      ) : null}
    </div>
  )
}

export { DetailField }

import gql from 'graphql-tag'

export const getUserProfile = gql`
	query($id: String!) {
		customer(id: $id) {
			id
			username
			email
			phoneNumber
		}
	}
`
